.interaction-screen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.interaction-text {
	display: flex;
	-webkit-flex-flow: column;
	flex-flow: column;
	width: 80%;
	text-align: center;
	color: #fff;
	font-size: 32px;
	flex-basis: 33%;
	align-items: center;
	justify-content: flex-end;
	text-transform: uppercase;
	white-space: pre-line;
}

.interaction-container {
	display: flex;
	-webkit-flex-flow: column;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	flex-basis: 66%;
	width: 100%;
	height: 100%;
	color: #fff;
	text-align: center;
}
