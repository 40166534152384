@import 'base';
@import 'settings';
@import 'typography';
@import 'modules/default';
@import 'modules/player';
@import 'modules/text';
@import 'modules/interaction';
@import 'modules/awake';
@import 'modules/states';

html,
body {
	min-height: 100%;
	background-color: #f6f5f4;
	margin: 0;
	padding: 0;

	/* prevent user text selection */
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

#app {
	width: 100%;
	height: 100%;
}
