@import 'settings';

* {
	touch-action: manipulation;
}

.logo {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 300px;
	height: 94px;
	background-image: $logo;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
	background-origin: content-box;

	@include max-size-xs {
		width: 200px;
		height: 70px;
	}
}

@font-face {
	font-family: 'edmondsans-sq';
	src: url('../fonts/edmondsans-bold-webfont.eot');
	src: url('../fonts/edmondsans-bold-webfont.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/edmondsans-bold-webfont.woff') format('woff'),
		url('../fonts/edmondsans-bold-webfont.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@-moz-keyframes spin {
	from {
		-moz-transform: rotate(-90deg);
	}
	to {
		-moz-transform: rotate(270deg);
	}
}
@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(-90deg);
	}
	to {
		-webkit-transform: rotate(270deg);
	}
}
@keyframes spin {
	from {
		transform: rotate(-90deg);
	}
	to {
		transform: rotate(270deg);
	}
}
