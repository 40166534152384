html,
body {
	font-size: $typography-font-size;
	font-family: $typography-font-family;
	color: $color-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $typography-heading-font-family;
	line-height: $typography-heading-line-height;
	letter-spacing: 0.05em;
	margin: 0;
}

h1 {
	font-size: $typography-h1-font-size;
	margin-bottom: $padding;

	@include max-size-xs {
		font-size: $typography-h1-mobile-font-size;
	}
}

h2 {
	font-size: $typography-h2-font-size;
	margin-bottom: $padding * 0.75;

	@include max-size-xs {
		font-size: $typography-h2-mobile-font-size;
	}
}

h3 {
	font-size: $typography-h3-font-size;
}

a {
	text-decoration: none;
}

p {
	font-size: $typography-body-font-size;
	line-height: $typography-line-height;
	letter-spacing: 0.05em;
	margin-top: 0;

	@include max-size-xs {
		font-size: $typography-body-mobile-font-size;
	}

	&:last-child {
		margin-bottom: 0;
	}
}
