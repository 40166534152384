@import '../settings';

.instruction-text {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	width: 90%;
	color: $color-white;
	text-transform: uppercase;
	white-space: pre-line;
	margin: auto;
	flex-grow: 1;
	font-size: 1.5rem;
}
