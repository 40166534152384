@import '../settings';

.screen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-height: 500px;

	display: flex;
	flex-flow: column;
	align-items: center;

	@include max-size-xs {
		min-height: 350px;
	}
}

.box {
	display: flex;
	-webkit-flex-flow: column;
	flex-flow: column;
	width: 90%;
	height: 100%;

	.row.header {
		flex: 0 1 auto;
		padding: $padding-small 0px;
	}

	.row.content {
		display: flex;
		flex: 1 1 auto;
		align-items: center;
		justify-content: center;
		text-align: center;
		min-height: 170px;
	}

	.row.footer {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: $padding-small 0;

		font-size: $typography-body-font-size;
		line-height: $typography-line-height;
		letter-spacing: 0.05em;
		margin-top: 0;

		@include max-size-xs {
			font-size: $typography-body-mobile-font-size;
		}
	}
}

.row.content {
	div {
		margin-left: auto;
		margin-right: auto;
	}

	.icon {
		margin: 0px 20px;
		width: 200px;
		height: 200px;
		background-repeat: no-repeat;
		background-size: 120px 120px;
		background-position: center center;

		@include max-size-xs {
			width: 130px;
			height: 130px;
			background-size: 80px 80px;
		}
	}
}

.row.footer {
	.social .social-btn {
		width: 60px;
		height: 60px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		display: inline-block;
		margin: 0px 20px;

		@include max-size-xs {
			width: 50px;
			height: 50px;
			margin: 0 $padding-small;
		}
	}
	.social-btn.facebook {
		background-image: $facebook-icon;
	}
	.social-btn.twitter {
		background-image: $twitter-icon;
	}
	.social-btn.instagram {
		background-image: $instagram-icon;
	}
	.social-btn.linkedin {
		background-image: $linkedin-icon;
	}
}
