@import 'utilities/responsive';

// Sizes
$size-xs: 568px;
$size-m: 1000px;
$size-l: 1200px;
$size-xl: 1600px;

// colors
$color-white: #fff !default;
$color-black: #000 !default;
$color-grey: #f0f0f0 !default;
$color-green: #65a34c !default;
$color-red: #d24029 !default;

$logo: url('~../images/Logo.svg');
$logoWhite: url('~../images/LogoWhite.svg');

$awake-icon: url('~../images/Fingerprint.svg');
$error-icon: url('~../images/ErrorIcon.svg');
$play-icon: url('~../images/PlayIcon.svg');
$sync-icon: url('~../images/Synchronizing.png');
$check-icon: url('~../images/CheckIcon.svg');
$unmute-icon: url('~../images/Unmute.svg');
$volume-icon: url('~../images/Unmute.svg');
$sleep-icon: url('~../images/Sleep.svg');

$facebook-icon: url('~../images/IconFacebook.svg');
$twitter-icon: url('~../images/IconTwitter.svg');
$instagram-icon: url('~../images/IconInstagram.svg');
$linkedin-icon: url('~../images/IconLinkedin.svg');

// Typography
$typography-fallback: -apple-system, 'Helvetica Neue', 'Open Sans', Arial,
	sans-serif !default;
$typography-font-family: 'edmondsans-sq', $typography-fallback !default;
$typography-font-size: 100% !default;
$typography-line-height: 1.45 !default;
$typography-heading-font-family: 'edmondsans-sq', $typography-fallback !default;
$typography-heading-line-height: 1.25 !default;
$typography-h1-font-size: 3rem !default;
$typography-h1-mobile-font-size: 2.25rem !default;
$typography-h2-font-size: 2.7rem !default;
$typography-h2-mobile-font-size: 2.3rem !default;
$typography-h3-font-size: 2rem !default;
$typography-body-font-size: 1.8rem !default;
$typography-body-mobile-font-size: 1.5rem !default;
$typography-small-font-size: 1.4rem !default;
$typography-small-mobile-font-size: 1rem !default;

// Paddings
$padding: 2rem !default;
$padding-small: 10px !default;
