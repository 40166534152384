@mixin max-size-xs {
	@media (max-width: $size-xs) {
		@content;
	}
}

@mixin max-size-s {
	@media (max-width: $size-m - 1) {
		@content;
	}
}

@mixin min-size-m {
	@media (min-width: $size-m) {
		@content;
	}
}

@mixin min-size-l {
	@media (min-width: $size-l) {
		@content;
	}
}

@mixin min-size-xl {
	@media (min-width: $size-xl) {
		@content;
	}
}
