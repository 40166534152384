@import '../settings';

body.countdown {
	background-color: black;
	color: white;
}

.countdown .row.content .text {
	font-size: 10em;
	height: auto;
}

body.error {
	background-color: $color-red;
	color: white;
}

.error {
	.row.content .icon {
		border: 7px solid white;
		border-radius: 50%;
		background-image: $error-icon;
	}
	.logo {
		background-image: $logoWhite;
	}
}

.loading {
	.row.content .text {
		font-size: 4em;
	}
	.row.content .icon {
		display: none;
	}
}

body.playing {
	background-color: #000;
}

.ready .row.content .icon {
	border: 7px solid #000;
	border-radius: 50%;
	background-image: $play-icon;
}

.syncing .row.content .icon {
	background-image: $sync-icon;
	background-size: cover;
	-webkit-animation: spin 1s infinite; /* Safari 4+ */
	-moz-animation: spin 1s infinite; /* Fx 5+ */
	-o-animation: spin 1s infinite; /* Opera 12+ */
	animation: spin 1s infinite; /* IE 10+, Fx 29+ */
}

body.waiting {
	background-color: $color-green;
	color: white;
}

.waiting .row.content .icon {
	border: 7px solid white;
	border-radius: 50%;
	background-image: $check-icon;
}

.waiting {
	.row.content .icon.unmute {
		background-image: $unmute-icon;
	}
	.row.content .icon.volume {
		background-image: $volume-icon;
	}
	.row.content .icon.sleep {
		background-image: $sleep-icon;
	}
	.logo {
		background-image: $logoWhite;
	}
}
