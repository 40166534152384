@import '../settings';

@mixin awake-opacity-transition {
	transition: opacity 1.6s ease-out;
	-webkit-transition: opacity 1.6s ease-out;
}

.awake-button {
	border: 3px solid $color-white;
	border-radius: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	background-image: $awake-icon;
	background-origin: content-box;

	margin: auto;
	padding: 5px;
	width: 75px;
	height: 75px;

	@include awake-opacity-transition;
}

.awake-text {
	font-size: $typography-small-mobile-font-size;
	color: $color-white;
	text-align: center;
	padding: 1rem;
	@include awake-opacity-transition;
}

.show {
	opacity: 1;
}
.hide {
	opacity: 0;
}
